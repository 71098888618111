import { decrypt } from '@47ng/simple-e2ee'
import {
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import fs from 'fs'
import { NextPage } from 'next'
import Head from 'next/head'
import path from 'path'
import React from 'react'
import { Block } from 'src/components/Block'
import { Header } from 'src/components/Header'
import type { MetaFile } from 'src/scripts/encryptImages'

// --

function useHashState() {
  const [value, _setValue] = React.useState(() => {
    if (typeof window === 'undefined') {
      return '' // No access to hash on SSR/SSG
    } else {
      return window.location.hash.replace(/^#/, '')
    }
  })
  const setValue = React.useCallback((v: string) => {
    window.location.hash = v
  }, [])
  React.useEffect(() => {
    const onHashChange = () => {
      _setValue(window.location.hash.replace(/^#/, ''))
    }
    window.addEventListener('hashchange', onHashChange)
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [])
  return [value, setValue] as const
}

const IndexPage: NextPage<{ metaFile: string }> = ({ metaFile }) => {
  const [key, setKey] = useHashState()

  const [meta, setMeta] = React.useState<MetaFile>({
    title: '',
    description: '',
    blocks: []
  })

  React.useEffect(() => {
    try {
      const meta = decrypt<MetaFile>(metaFile, key)
      if (!meta) {
        return
      }
      setMeta(meta)
    } catch {}
  }, [key])

  return (
    <>
      <Head>
        <link rel="preload" href="/c7UJHaSMQ6LaOYSrFqCoQxSM.enc" as="fetch" />
      </Head>
      <Container as="header" maxW="4xl" mt={12}>
        <Header />
        {key && (
          <>
            <Skeleton minH={8} isLoaded={!!meta.title}>
              <Heading as="h1" mb={2}>
                {meta.title}
              </Heading>
            </Skeleton>
            <Skeleton minH={4} isLoaded={!!meta.description}>
              <Text
                fontSize="sm"
                mb={8}
                color={useColorModeValue('gray.500', 'gray.400')}
              >
                {meta.description}
              </Text>
            </Skeleton>
          </>
        )}
      </Container>
      {!key && (
        <Container>
          <Text my={12}>
            This page is private. You need a key to unlock it.
          </Text>
          <FormControl>
            <FormLabel>Enter the key</FormLabel>
            <Input value="" onChange={e => setKey(e.target.value)} />
          </FormControl>
        </Container>
      )}
      <Container as="main" maxW="6xl">
        {meta.blocks.map((block, i) => (
          <Block key={i} meta={block} />
        ))}
      </Container>
    </>
  )
}

export default IndexPage

export async function getStaticProps() {
  return {
    props: {
      metaFile: (
        await fs.promises.readFile(
          path.resolve(process.cwd(), 'public/meta.enc')
        )
      ).toString('utf-8')
    }
  }
}
