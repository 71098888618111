import { Box, ChakraProps, Container, Text } from '@chakra-ui/react'
import React from 'react'
import { MetaFileBlock } from 'src/scripts/encryptImages'
import { EncryptedImage } from './EncryptedImage'
import { ImageGallery } from './ImageGallery'

export interface BlockProps extends ChakraProps {
  meta: MetaFileBlock
}

export const Block: React.FC<BlockProps> = ({ meta, ...props }) => {
  if (meta.type === 'Image') {
    return <EncryptedImage {...meta.props} {...props} featured />
  }
  if (meta.type === 'ImageGallery') {
    return (
      <ImageGallery my={12} {...meta.props} {...props}>
        {meta.children.map(child => (
          <EncryptedImage key={child.props.src} {...child.props} />
        ))}
      </ImageGallery>
    )
  }
  if (meta.type === 'Text') {
    return (
      <Container {...props}>
        <Text my={12} {...meta.props}>
          {meta.content}
        </Text>
      </Container>
    )
  }
  return <Box {...props}></Box>
}
