import { b64 } from '@47ng/codec'
import { getKeyFromURL } from '@47ng/simple-e2ee'
import {
  chakra,
  Image,
  ImageProps,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import React from 'react'
import { ImageBlock } from 'src/scripts/encryptImages'
import nacl from 'tweetnacl'

export type EncryptedImageProps = Omit<ImageProps, 'src'> &
  ImageBlock['props'] & {
    featured?: boolean
  }

export const EncryptedImage: React.FC<EncryptedImageProps> = ({
  src,
  alt,
  featured = false,
  caption,
  ...props
}) => {
  const imgRef = React.useRef<any>()
  const [imgURL, setImgURL] = React.useState<string>()
  const [error, setError] = React.useState<string>()

  React.useEffect(() => {
    try {
      const key = getKeyFromURL() // Will throw if no key available
      fetch(src)
        .then(async res => {
          const payload = await res.text()
          const [, nonce, ciphertext] = payload.split('.')
          const buffer = nacl.secretbox.open(
            b64.decode(ciphertext),
            b64.decode(nonce),
            b64.decode(key)
          )
          if (!buffer) {
            throw new Error('Could not decrypt image')
          }
          setImgURL(URL.createObjectURL(new Blob([buffer])))
        })
        .catch(e => setError(e.message))
    } catch (e) {
      setError(e.message)
    }
  }, [src])

  React.useEffect(() => {
    if (imgURL) {
      imgRef.current.src = imgURL
    }
    return () => {
      if (imgURL) URL.revokeObjectURL(imgURL)
    }
  }, [imgURL])

  const layoutProps = {
    bg: imgURL ? undefined : useColorModeValue('gray.200', 'gray.700'),
    fontSize: 'xs',
    mx: 'auto',
    py: imgURL ? 0 : 12,
    textAlign: 'center',
    verticalAlign: 'middle',
    fit: 'cover'
  } as const

  const imgProps = {
    ref: imgRef,
    mx: 'auto',
    alt: error ?? alt,
    fit: 'cover',
    transition: 'all .15s ease-out',
    rounded: 'sm',
    _hover: featured
      ? {}
      : {
          transform: 'scale(1.05)',
          shadow: 'lg'
        }
  } as const

  return (
    <chakra.figure {...layoutProps}>
      <Image {...imgProps} {...props} />
      {caption && (
        <Text
          as="figcaption"
          fontSize="sm"
          textAlign="center"
          mt={1}
          color={useColorModeValue('gray.500', 'gray.400')}
        >
          {caption}
        </Text>
      )}
    </chakra.figure>
  )
}
