import { Grid, GridProps } from '@chakra-ui/react'
import React from 'react'

export interface ImageGalleryProps extends GridProps {}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  children,
  ...props
}) => {
  const numChildren = React.Children.count(children)

  return (
    <Grid
      templateColumns={['repeat(3, 1fr)', 'repeat(5, 1fr)']}
      templateRows={[
        `repeat(${Math.ceil(numChildren / 3)}, 24vw)`,
        `repeat(${Math.ceil(numChildren / 5)}, 14vw)`
      ]} // 8 -> 5, 3 -> 24
      gap={4}
      sx={{
        '& img, &> figure': {
          w: '100%',
          h: '100%'
        },
        '& figcaption': {
          display: 'none'
        }
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}
