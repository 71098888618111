import {
  Flex,
  FlexProps,
  HStack,
  IconButton,
  useColorMode
} from '@chakra-ui/react'
import React from 'react'
import { FiMoon, FiSun } from 'react-icons/fi'

export interface HeaderProps extends FlexProps {}

export const Header: React.FC<HeaderProps> = ({ ...props }) => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Flex as="header" justifyContent="flex-end" alignItems="center" {...props}>
      <HStack as="nav">
        <IconButton
          variant="ghost"
          aria-label={colorMode === 'dark' ? 'Mode Nuit' : 'Mode Jour'}
          icon={colorMode === 'dark' ? <FiMoon /> : <FiSun />}
          isRound
          onMouseDown={toggleColorMode}
        />
      </HStack>
    </Flex>
  )
}
